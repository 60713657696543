<template>
    <div class="productionDeliveryBoxEcharts">
        <div class="productionDeliveryMonthEcharts">
            <el-date-picker
                v-model="value1"
                type="monthrange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                format="yyyy-MM"
                value-format="yyyy-MM"
                @change="changePicker"
            >
            </el-date-picker>
            <div class="top-echarts-two">
                <div class="echarts-left echarts-total">
                    <div class="title-box">
                        <h1>场站对比</h1>
                        <el-select class="stationSeleced" v-model="stationSeleced" @change="changeSelect">
                            <el-option
                                label="发货方量"
                                value="1"
                            >
                            </el-option>
                            <el-option
                                label="签收方量"
                                value="2"
                            >
                            </el-option>
                        </el-select>
                        <span class="lookMore" @click="lookMore(1)">查看完整数据</span>
                    </div>
                    <div style="width 100%;height:3rem;flex 4" id="contractQuantityId"></div>
                </div>
                <div class="echarts-right echarts-total">
                    <div class="title-box">
                        <h1>近半年趋势</h1>
                        <el-select
                            class="stationSeleced"
                            v-model="stationName"
                            @change="selectChange"
                        >
                            <el-option
                                v-for="(item, index) in stationList"
                                :key="`station_${index}`"
                                :label="item"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                        <span class="lookMore" @click="lookMore(2)">查看完整数据</span>
                    </div>
                    <div style="width 100%;height:300px;flex 4" id="estimatedSalesId"></div>
                </div>
            </div>
            <!-- 导出excel -->
            <exportDom :dom-id="'.el-table__fixed-right'" :excel-name="'按场站生产发货统计'"></exportDom>
            <div class="table-list">
                <el-table
                    :data="tabListDataName"
                    style="width: 100%"
                    fixed
                    max-height="280"
                    show-summary
                    :summary-method="getSummaries"
                >
                    <el-table-column
                        prop="name"
                        label="公司名称"
                        width="150"
                        fixed
                    >
                    </el-table-column>
                    <el-table-column
                        v-for="(date, index) in tabListDataDate"
                        :key="index"
                        :label="date"
                        width="113"
                    >
                        <el-table-column
                            label="发货方量(方)"
                            width="113"
                            :prop="date"
                        >
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.fhfl }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="签收方量(方)"
                            width="113"
                            :prop="date"
                        >
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.qsfl }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="签收率"
                            width="113"
                            :prop="date"
                        >
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.qsl }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                        prop="ljfhfl"
                        fixed="right"
                        width="100"
                    >
                        <template #header>
                            累积发货<br>方量(方)
                        </template>
                        <template slot-scope="scope">
                            {{ scope.row.ljfhfl.toFixed(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="ljqsfl"
                        fixed="right"
                        width="100"
                    >
                        <template #header>
                            累积签收<br>方量(方)
                        </template>
                        <template slot-scope="scope">
                            {{ scope.row.ljqsfl.toFixed(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="ljqsl"
                        label="累积签收率"
                        fixed="right"
                        width="110"
                    >
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <alertEcharts v-if="showContract" :alert-status="alertStatus" @closeChildAlert="closeChildFn"></alertEcharts>
        <byLabelChild v-if="showByLabelChild" @closeChildAlert="closeChildFn"></byLabelChild>
    </div>
</template>

<script>
import exportDom from './../salesStatistics/components/export';
// import addBgImages from './utils';
import alertEcharts from './components/productionDelivery/productionDeliveryMore';
import byLabelChild from './components/productionDelivery/productionDeliveryChild';
export default {
    data() {
        return {
            showContract: false,
            showByLabelChild: false,
            alertStatus: 0,
            tabListData: [],
            tabListDataName: [],
            tabListDataList: [],
            tabListDataDate: [],
            tabListEchartsHt: [],
            echartsColor: ['#EA8343', '#3E79C4', '#E6E429', '#FA9E94'],
            value1: [],
            stationSeleced: '1',
            // 发货图表数据
            fhChartData: [],
            // 签收图表数据
            qsChartData: [],
            // 近半年
            // 开始日期
            startDate: '',
            // 结束日期
            endDate: '',
            // 近半年所有数据集合
            originList: [],
            originDate: [],
            // 场站
            stationList: [],
            stationName: '全部场站',
        };
    },
    components: {
        alertEcharts,
        byLabelChild,
        exportDom,
    },
    mounted() {
        this.$nextTick(() => {
            const dateTime = this.switchTime() + '-01-01';
            this.value1 = [dateTime, this.switchTime('all')];
            this.startDate = dateTime;
            this.endDate = this.switchTime('all');
            // 生产发货统计
            this.salescontractMonths();
            // 近半年趋势
            this.halfYearData();
        });
    },
    methods: {
        // 发货、签收切换
        changeSelect() {
            if (this.stationSeleced === '1') {
                this.contractQuantityCh(this.fhChartData);
            } else {
                this.contractQuantityCh(this.qsChartData);
            }
        },
        // 近半年趋势-按场站筛选
        selectChange() {
            if (this.stationName === '全部场站') {
                const data = {
                    chartLegend: ['发货方量', '签收方量', '签收率' ],
                    xAxisData: [],
                    yAxisData: [],
                };
                data.xAxisData = this.originDate;
                const totalData = this.allStation();
                data.yAxisData[0] = totalData[0];
                data.yAxisData[1] = totalData[1];
                data.yAxisData[2] = totalData[2];
                this.halfYearChart(data);
            } else {
                const fhData = [];
                const qsData = [];
                const qslData = [];
                this.originDate.map(item => {
                    const arr = this.originList.filter(key => {
                        return key.date === item && key.name === this.stationName;
                    });
                    fhData.push(arr[0].fhfl);
                    qsData.push(arr[0].qsfl);
                    let qsl = 0;
                    if (arr[0].fhfl && arr[0].qsfl) {
                        qsl = parseFloat((arr[0].qsfl / arr[0].fhfl * 100).toFixed(2));
                    } else {
                        qsl = 0;
                    }
                    qslData.push(qsl);
                });
                const data = {
                    chartLegend: ['发货方量', '签收方量', '签收率' ],
                    xAxisData: [],
                    yAxisData: [],
                };
                data.xAxisData = this.originDate;
                data.yAxisData[0] = fhData;
                data.yAxisData[1] = qsData;
                data.yAxisData[2] = qslData;
                this.halfYearChart(data);
            }

        },
        // 近半年趋势图表数据转换
        async halfYearData() {
            const res = await this.salescontractMonthsApi();
            if (res) {
                this.originList = res.list;
                this.originDate = res.date;
                res.name.unshift('全部场站');
                this.stationList = res.name;
                const data = {
                    chartLegend: ['发货方量', '签收方量', '签收率' ],
                    xAxisData: [],
                    yAxisData: [],
                };
                data.xAxisData = res.date;
                const totalData = this.allStation();
                data.yAxisData[0] = totalData[0];
                data.yAxisData[1] = totalData[1];
                data.yAxisData[2] = totalData[2];
                this.halfYearChart(data);
            }
        },
        // 所有场站近半年数据
        allStation() {
            const data = [];
            // 发货方量合计
            const fhTotalData = this.originDate.map(item => {
                const arr = this.originList.filter(key => {
                    return key.date === item;
                });
                let total = 0;
                arr.forEach(a => {
                    total = Math.round(total * 100 + a.fhfl * 100) / 100;
                });
                return total;
            });
            // 签收方量合计
            const qsTotalData = this.originDate.map(item => {
                const arr = this.originList.filter(key => {
                    return key.date === item;
                });
                let total = 0;
                arr.forEach(a => {
                    total = Math.round(total * 100 + a.qsfl * 100) / 100;
                });
                return total;
            });
            // 签收率合计
            const qslTotalData = qsTotalData.map((item, index) => {
                let qsl = 0;
                if (fhTotalData[index] !== 0) {
                    qsl = parseFloat((item / fhTotalData[index] * 100).toFixed(2));
                } else {
                    qsl = 0;
                }

                return qsl;
            });
            data.push(fhTotalData);
            data.push(qsTotalData);
            data.push(qslTotalData);
            return data;
        },

        // 拉取近半年趋势
        salescontractMonthsApi() {
            return this.$axios
                .get(`/interfaceApi/report/produceanalysis/scfh/cz/months?startDate=${this.startDate}&endDate=${this.endDate}`);
        },
        changePicker() {
            this.salescontractMonths();
        },
        switchTime(isAall) {
            const date = new Date();
            const y = date.getFullYear(); // 获取完整的年份(4位,1970)
            let m = date.getMonth() + 1; // 获取月份(0-11,0代表1月,用的时候记得加上1)
            m = m > 9 ? m : '0' + m;
            if (isAall === 'all') {
                return y + '-' + m;
            }
            return y;
        },

        closeChildFn() {
            this.showByLabelChild = false;
            this.showContract = false;
        },
        // 查看详情
        lookMore(status) {
            // status 7:发货方量  8:签收方量
            this.alertStatus = status;
            this.showContract = true;
        },

        // 生产发货统计
        salescontractMonths() {
            this.$axios
                .get(`/interfaceApi/report/produceanalysis/scfh/cz/months?startDate=${this.value1[0]}&endDate=${this.value1[1]}`)
                .then(res => {
                    this.tabListData = res;
                    this.tabListDataList = res.list;
                    this.tabListDataDate = res.date;
                    // 表格渲染
                    this.tabListDataName = res.name.map(item => {
                        let ljfhfl = 0;
                        let ljqsfl = 0;
                        let ljqsl = 0;
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        arr.forEach(item => {
                            ljfhfl += Math.round(item.fhfl * 100) / 100;
                            ljqsfl += Math.round(item.qsfl * 100) / 100;
                            ljqsl = ljqsfl / ljfhfl;
                        });
                        return {
                            name: item,
                            list: arr,
                            ljfhfl: ljfhfl,
                            ljqsfl: ljqsfl,
                            ljqsl: isNaN(ljqsl) ? 0 + '%' : (ljqsl * 100).toFixed(2) + '%',
                        };
                    });
                    // 处理图表数据
                    this.tabListEchartsHt = res.name;
                    this.tabListEchartsHt.push('合计');
                    // 分组名称
                    const getSeriesData = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        return arr;
                    });
                    // 发货方量
                    let htEchartSeriesData = [];
                    htEchartSeriesData = getSeriesData.map((item, index) => {
                        let htItemListData = [];
                        htItemListData = item.map(info => {
                            return info.fhfl;
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const htEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: htItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return htEchartSeries;
                    });
                    // 签收方量
                    let flEchartSeriesData = [];
                    flEchartSeriesData = getSeriesData.map((item, index) => {
                        let flItemListData = [];
                        flItemListData = item.map(info => {
                            return info.qsfl;
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const flEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: flItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return flEchartSeries;
                    });
                    // 发货方量合计
                    const htTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.fhfl * 100) / 100;
                        });
                        return total;
                    });
                    const htTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: htTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'bottom',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(2, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    // 签收方量合计
                    const flTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.qsfl * 100) / 100;
                        });
                        return total;
                    });
                    const flTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: flTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'bottom',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(2, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    const htCompleteData = htEchartSeriesData.slice(0, -1);
                    htCompleteData.push(htTotal);
                    const flCompleteData = flEchartSeriesData.slice(0, -1);
                    flCompleteData.push(flTotal);
                    this.fhChartData = htCompleteData;
                    this.qsChartData = flCompleteData;
                    // 发货方量图表
                    this.contractQuantityCh(htCompleteData);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                // 获取与当前行时间相同的数据
                const arr = this.tabListDataList.filter(item => {
                    return column.property === item.date;
                });
                // 对应时间合同数量和预计发货方量(方)的数值数组 // 对应每一列的数据数组
                // eslint-disable-next-line consistent-return
                let values = arr.map(item => {
                    if (column.label === '发货方量(方)') {return item.fhfl;}
                    if (column.label === '签收方量(方)') {return item.qsfl;}
                });
                if (column.label === '累积发货方量(方)') {
                    // eslint-disable-next-line consistent-return
                    values = data.map(item => {
                        if (column.label === '累积发货方量(方)') {
                            return item.ljfhfl;
                        }
                    });
                }
                if (column.label === '累积签收方量(方)') {
                    // eslint-disable-next-line consistent-return
                    values = data.map(item => {
                        if (column.label === '累积签收方量(方)') {
                            return item.ljqsfl;
                        }
                    });
                }
                // const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return Number((prev + curr).toFixed(2));
                        }
                        return Number(prev.toFixed());
                    }, 0);
                } else {
                    sums[index] = ' ';
                    if (column.label === '签收率') {
                        sums[index] = !Number(sums[index - 2]) ? ' ' : (sums[index - 1] / sums[index - 2] * 100).toFixed(2) + '%';
                    } else if (column.label === '累积签收率') {
                        // 计算合计签收率
                        sums[index] = !Number(sums[index - 2]) ? ' ' : (sums[index - 1] / sums[index - 2] * 100).toFixed(2) + '%';
                    }
                }
            });
            return sums;
        },
        // 发货方量
        halfYearChart(data) {
            const myChart = this.$echarts.init(document.getElementById('estimatedSalesId'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: data.chartLegend,
                    padding: [10, 0, 0, 0],
                    color: ['blue', 'pink', 'gray'],
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: data.xAxisData,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: '发货方量',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: '#44A1F5',
                            },
                        },
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            color: '#44A1F5',
                            fontSize: '14',
                            padding: 5,
                        },
                        data: data.yAxisData[0],
                    },
                    {
                        name: '签收方量',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: '#EA8243',
                            },
                        },
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            color: '#EA8243',
                            fontSize: '14',
                            padding: 5,
                        },
                        data: data.yAxisData[1],
                    },
                    {
                        name: '签收率',
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                color: '#00B59C',
                            },
                        },
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            color: '#00B59C',
                            fontSize: '14',
                            padding: 5,
                        },
                        data: data.yAxisData[2],
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        // 发货方量
        contractQuantityCh(data) {
            const myChart = this.$echarts.init(document.getElementById('contractQuantityId'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    type: 'scroll',
                    data: this.tabListEchartsHt,
                    padding: [10, 0, 0, 0],
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListDataDate,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: data,
            };
            const that = this;
            myChart.on('click', function () {
                that.showByLabelChild = true;
            });
            myChart.setOption(option, true);
        },
    },
};

</script>
<style lang='stylus'>
.productionDeliveryBoxEcharts
    height 100%
.productionDeliveryMonthEcharts
    height 100%
    display flex
    padding .2rem
    padding-top .1rem
    display flex
    flex-direction column
    background #fff
    overflow-y auto
    .el-table__fixed-right thead tr th
        &:last-child
            background #F5F2ED !important
            color #823802 !important
        &:nth-last-child(2)
            background #F5F2ED !important
            color #823802 !important
         &:nth-last-child(3)
            background #F5F2ED !important
            color #823802 !important
    .el-table__fixed-footer-wrapper table tbody tr
        td
            background none
            color #C9661E
            &:first-child
                background #F5F2ED
                color #823802
                font-size .16rem
    .el-table__footer-wrapper table tbody td
        background none
    .top-echarts-two
        display flex
        width 100%
        height 3.48rem
        margin-top .1rem
        .echarts-total
            flex 1
            padding .15rem
            border 1px solid #D7D7D7
            display flex
            flex-direction column
            position relative
            .title-box
                h1
                    color #333333
                    font-size .18rem
                    float left
                    &:before
                        content '`'
                        width .06rem
                        height .2rem
                        background #4D7DE0
                        color #4D7DE0
                        margin-right .1rem
                .stationSeleced
                    position absolute
                    right 1.5rem
                    top .09rem
                    width 1.2rem
                .lookMore
                    position absolute
                    right .1rem
                    top .1rem
                    color #1577d2
                    height 0.25rem
                    padding 0 0.2rem
                    font-size 0.14rem
                    cursor pointer
                    border 1px solid #1577d2
            .el-select
                .el-input__inner
                    height .28rem
                    line-height .28rem
                .el-select__caret
                    line-height .28rem
        .echarts-left
            margin-right .2rem
    .table-list
        margin-top .1rem
        width 100%
        height calc(100% - 3.68rem)
        padding-bottom .2rem
        .el-table
            .el-table__header
                border-collapse: collapse;
            thead tr th
                color #022782
                font-size .16rem
                text-align center
                padding 0
            tbody tr td
                height .4rem
                text-align center
                padding 0
                font-size .14rem
</style>
