<!--
 * @Author: your name
 * @Date: 2020-09-09 17:59:14
 * @LastEditTime: 2020-09-10 11:13:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\statisticalAnalysis\salesStatistics\components\export.vue
-->
<template>
    <div class="export">
        <button class="export_btn" @click="exportExcel">
            <i class="iconfont icondaochu"></i>
            <span>导出excel</span>
        </button>
    </div>
</template>

<script>
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
export default {
    components: {},
    props: {
        domId: {
            type: String,
        },
        excelName: {
            type: String,
        },
    },
    data() {
        return {
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {},
    methods: {
        exportExcel() {
            /* 从表生成工作簿对象 */
            const wb = XLSX.utils.table_to_book(document.querySelector(this.domId));
            /* 获取二进制字符串作为输出 */
            const wbout = XLSX.write(wb, {
                bookType: 'xlsx',
                bookSST: true,
                type: 'array',
            });
            try {
                FileSaver.saveAs(
                // Blob 对象表示一个不可变、原始数据的类文件对象。
                // Blob 表示的不一定是JavaScript原生格式的数据。
                // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
                // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
                    new Blob([wbout], { type: 'application/octet-stream' }),
                    // 设置导出文件名称
                    this.excelName + '.xlsx'
                );
            } catch (e) {
                if (typeof console !== 'undefined') {console.log(e, wbout);}
            }
            return wbout;
        },
    },
};
</script>
<style lang="stylus" scoped>
.export
    margin-top .1rem
    height .35rem
    .export_btn
        float right
        border: none;
        height: 0.36rem;
        font-size: 0.14rem;
        min-width: 0.36rem;
        line-height: 0.36rem;
        cursor: pointer;
        padding: 0 0.2rem;
        border-radius: 2px;
        background: #f1f4f7;
        color: #2978ff;
        text-align: center;
</style>
